import React from 'react';
import SVG from '@newrelic/gatsby-theme-newrelic/src/components/SVG';

const PythonIcon = (props) => {
  return (
    <SVG {...props} viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <path
          d="M9.318 4.873c0-3.515 12.822-4.253 13.43.484v6.649c0 2.234-1.918 3.555-3.537 3.555h-4.762c-4.968.013-6.37.357-6.053 7.3H6.239c-5.422 0-5.816-13.349 0-13.578h9.842v-.95H9.317V5.177zm2.915-.665c-.591.015-1.25.463-1.25 1.245s.593 1.205 1.185 1.19c.592-.014 1.244-.438 1.244-1.25 0-.811-.588-1.2-1.18-1.185z"
          fill="#347AB4"
        />
        <path
          d="M22.72 27.126c0 3.515-12.823 4.253-13.431-.484v-6.649c0-2.614 1.685-3.555 3.537-3.555h4.03c5.024-.006 7.075-.22 6.786-7.3h2.156c5.38 0 5.816 13.348 0 13.578h-9.842v.95h6.764v3.46zm-2.916.664c.591-.014 1.25-.462 1.25-1.244s-.592-1.205-1.185-1.19c-.592.014-1.244.438-1.244 1.25 0 .811.588 1.199 1.18 1.184z"
          fill="#FFCA1D"
        />
      </g>
    </SVG>
  );
};

export default PythonIcon;
