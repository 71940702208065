import React from 'react';
import SVG from '@newrelic/gatsby-theme-newrelic/src/components/SVG';

const AndroidColorIcon = (props) => {
  return (
    <SVG {...props} viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.246 5.32l1.287-1.87c.077-.11.058-.256-.042-.325s-.243-.035-.32.076l-1.337 1.943a7.89 7.89 0 00-2.894-.541 7.887 7.887 0 00-2.893.54l-1.338-1.942c-.077-.11-.22-.145-.32-.076-.1.068-.119.214-.042.326l1.288 1.87c-2.048.952-3.484 2.762-3.667 4.875h13.944c-.182-2.113-1.619-3.923-3.666-4.876"
          fill="#9FBF3B"
        />
        <path
          d="M13.027 8.267a.771.771 0 110-1.542.771.771 0 010 1.542M18.853 8.267a.771.771 0 110-1.542.771.771 0 010 1.542"
          fill="#FFF"
        />
        <path
          d="M8.072 19.008a1.556 1.556 0 11-3.111 0v-6.292a1.556 1.556 0 013.111 0v6.292zM8.967 11.209v11.364c0 .675.548 1.222 1.223 1.222h1.445v3.496a1.555 1.555 0 103.111 0v-3.496h2.387v3.496a1.555 1.555 0 103.111 0v-3.496h1.446c.675 0 1.222-.547 1.222-1.222V11.209H8.967zM23.807 19.008a1.556 1.556 0 103.111 0v-6.292a1.556 1.556 0 00-3.111 0v6.292z"
          fill="#9FBF3B"
        />
      </g>
    </SVG>
  );
};

export default AndroidColorIcon;
