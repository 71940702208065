import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import SVG from '@newrelic/gatsby-theme-newrelic/src/components/SVG';

const VulnerabilityManagementIcon = (props) => (
  <SVG
    {...props}
    viewBox="0 0 16 16"
    css={css`
      fill: currentColor;
      stroke: currentColor;
      stroke-width: 0.25;
    `}
  >
    <path d="M0 4L7.5 0L13.4822 3.19048L12.4486 3.76466L7.5 1.1L1 4.6V11.4L5.99991 14.0923V15.2L0 12V4Z" />
    <path d="M7.49991 16V14.9L14 11.4V4.6L13.9945 4.59706L14.9909 3.99512L15 4V12L7.49991 16Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.49994 5.57083V6.6375H10.9999V11.4375H3.99994V6.6375H5.49994V5.57083C5.49994 4.3975 6.39994 3.4375 7.49994 3.4375C8.59994 3.4375 9.49994 4.3975 9.49994 5.57083ZM7.49994 4.39062C6.64994 4.39062 6.49994 4.98438 6.49994 5.57083V6.6375H8.49994V5.57083C8.49994 4.9375 8.34994 4.39062 7.49994 4.39062ZM4.99994 7.4375V10.4375H9.99994V7.4375H4.99994Z"
    />
  </SVG>
);

VulnerabilityManagementIcon.propTypes = {
  size: PropTypes.string,
};

export default VulnerabilityManagementIcon;
