import React from 'react';
import SVG from '@newrelic/gatsby-theme-newrelic/src/components/SVG';

const CIcon = (props) => {
  return (
    <SVG {...props} viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <path
          d="M14.788 1.204a1.605 1.605 0 011.566 0L28.36 7.943c.485.271.784.774.784 1.318v13.477c0 .544-.299 1.047-.784 1.32l-12.005 6.738a1.605 1.605 0 01-1.566 0L2.783 24.057A1.515 1.515 0 012 22.74V9.262c0-.544.299-1.047.784-1.32l12.004-6.738z"
          fill="#283593"
        />
        <path
          d="M2.217 23.431c-.19-.254-.217-.495-.217-.82V9.21c0-.541.298-1.041.781-1.311l11.963-6.7a1.722 1.722 0 011.622.006c2.388 1.337 9.551 5.328 11.939 6.665.193.108.34.239.471.411L2.217 23.432z"
          fill="#5C6BC0"
        />
        <path
          d="M15.571 6c5.52 0 10 4.48 10 10s-4.48 10-10 10c-5.519 0-10-4.48-10-10s4.481-10 10-10zm0 5c2.76 0 5 2.24 5 5s-2.24 5-5 5c-2.759 0-5-2.24-5-5s2.24-5 5-5z"
          fill="#FFF"
        />
        <path
          d="M28.775 8.29c.369.344.361.864.361 1.274 0 2.71-.022 10.42.007 13.131.003.283-.09.58-.23.805L15.28 16l13.494-7.71z"
          fill="#3949AB"
        />
      </g>
    </SVG>
  );
};

export default CIcon;
