import React from 'react';
import SVG from '@newrelic/gatsby-theme-newrelic/src/components/SVG';

const OpenTelemetryColorIcon = (props) => {
  return (
    <SVG {...props} viewBox="0 0 32 32">
      <g fillRule="nonzero" fill="none">
        <path
          d="M16.873 17.397a3.272 3.272 0 104.628 4.627 3.272 3.272 0 00-4.628-4.627zm3.46 3.462a1.62 1.62 0 11-2.291-2.29 1.62 1.62 0 012.29 2.29zm1.113-19.564L19.441 3.3a1.016 1.016 0 000 1.43l7.829 7.829a1.016 1.016 0 001.43 0l2.005-2.005a1.016 1.016 0 000-1.43l-7.832-7.829a1.012 1.012 0 00-1.427 0zM7.4 26.917a.916.916 0 000-1.293l-1.02-1.02a.916.916 0 00-1.293 0L2.981 26.71l-.003.003-.578-.578a.817.817 0 10-1.157 1.156l3.469 3.469a.817.817 0 101.156-1.157l-.578-.578.003-.003L7.4 26.917z"
          fill="#F5A800"
        />
        <path
          d="M18.005 7.052l-4.454 4.454a1.025 1.025 0 000 1.442l2.75 2.75a4.975 4.975 0 016.42.524l2.227-2.227a1.025 1.025 0 000-1.442l-5.5-5.501a1.02 1.02 0 00-1.443 0zm-2.86 9.806l-1.625-1.625a.982.982 0 00-1.384 0l-5.73 5.733a.982.982 0 000 1.384l3.247 3.246a.982.982 0 001.385 0l3.684-3.69a4.984 4.984 0 01.423-5.048z"
          fill="#425CC7"
        />
      </g>
    </SVG>
  );
};

export default OpenTelemetryColorIcon;
