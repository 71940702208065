import React from 'react';
import SVG from '@newrelic/gatsby-theme-newrelic/src/components/SVG';

const PrometheusIcon = (props) => {
  return (
    <SVG {...props} viewBox="0 0 32 32">
      <path
        d="M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zm0 24.33c-2.043 0-3.699-1.364-3.699-3.047H19.7c0 1.683-1.656 3.048-3.699 3.048zm6.11-4.056H9.89V21.06h12.22v2.215zm-.044-3.356H9.925c-.04-.046-.082-.092-.12-.14-1.251-1.518-1.546-2.311-1.832-3.12-.005-.026 1.517.312 2.596.554 0 0 .555.129 1.366.277-.779-.914-1.242-2.075-1.242-3.262 0-2.606 1.999-4.883 1.278-6.724.702.057 1.452 1.481 1.503 3.708.746-1.031 1.058-2.914 1.058-4.068 0-1.196.788-2.584 1.576-2.632-.703 1.158.182 2.15.968 4.611.294.925.257 2.48.484 3.468.076-2.05.428-5.04 1.728-6.072-.573 1.3.085 2.927.536 3.709.726 1.261 1.167 2.217 1.167 4.025a5.029 5.029 0 01-1.203 3.246c.858-.16 1.451-.306 1.451-.306l2.788-.544s-.405 1.666-1.961 3.27z"
        fill="#E6522C"
        fillRule="nonzero"
      />
    </SVG>
  );
};

export default PrometheusIcon;
