import React from 'react';
import SVG from '@newrelic/gatsby-theme-newrelic/src/components/SVG';

const MySQLIcon = (props) => {
  return (
    <SVG {...props} viewBox="0 0 32 32">
      <path
        d="M28 27.4l-.5-.5c-.5-.6-1.1-1.2-1.7-1.7-.5-.4-1.7-.9-1.9-1.5.4 0 .8-.2 1.1-.3.6-.1 1.1-.1 1.7-.3.3-.1.5-.1.8-.2v-.2c-.3-.3-.5-.7-.8-1-.8-.7-1.8-1.4-2.7-2-.5-.3-1.2-.5-1.7-.8-.2-.1-.5-.2-.6-.3-.3-.4-.5-.8-.7-1.3-.5-.9-.9-1.9-1.4-2.9-.3-.6-.5-1.3-.8-1.9-2-2.7-3.8-4.5-6.7-6.1-.6-.4-1.3-.5-2.1-.7-.4 0-.8 0-1.2-.1-.3-.1-.6-.4-.8-.6-1-.6-3.4-1.9-4.1-.1-.5 1 .6 2.1 1 2.6.3.4.6.8.8 1.2.2.4.2.7.3 1 .3.7.5 1.5.9 2.2.2.3.4.7.6 1 .1.2.4.3.4.6-.2.3-.2.8-.4 1.2-.5 1.9-.3 4.3.5 5.6.3.4.9 1.3 1.7 1 .7-.3.6-1.2.8-2.1 0-.2 0-.3.1-.5.2.5.5.9.7 1.4.5.8 1.4 1.7 2.2 2.2.4.3.7.8 1.2 1v-.1c-.1-.1-.2-.2-.4-.3-.3-.3-.6-.7-.9-1-.7-.9-1.3-2-1.9-3-.3-.5-.5-1.1-.7-1.6-.1-.2-.1-.5-.3-.6-.2.4-.6.7-.8 1.1-.3.7-.4 1.6-.5 2.5h-.1c-.5-.1-.7-.7-.9-1.1-.5-1.2-.6-3.1-.2-4.4.1-.3.6-1.4.4-1.8-.1-.3-.4-.5-.6-.7-.2-.3-.5-.7-.6-1.1-.4-.9-.6-2-1-2.9 0-.3-.3-.8-.6-1.2-.3-.4-.7-.7-.9-1.3-.1-.1-.2-.4-.1-.6 0-.2.1-.2.2-.3.2-.2.8 0 1 .1.7.3 1.2.6 1.7.9.3.2.5.5.8.6h.3c.5.1 1.1 0 1.6.2.9.3 1.7.7 2.4 1.1 2.2 1.4 4 3.3 5.2 5.7.2.4.3.7.5 1.1.3.8.8 1.6 1.1 2.4.3.8.7 1.6 1.2 2.2.2.3 1.2.5 1.7.7.3.1.8.3 1.1.5.6.3 1.1.7 1.7 1.1.3.2 1.1.6 1.1.9-1.3 0-2.4.1-3.2.5-.2.1-.6.1-.7.4.1.1.2.3.3.5.2.3.5.8.9 1 .3.3.7.5 1.1.8.6.4 1.4.6 2 1 .4.2.7.5 1.1.8.2.1.3.3.5.4-.1 0-.1-.2-.2-.4z"
        fill="#00546B"
        fillRule="evenodd"
      />
    </SVG>
  );
};

export default MySQLIcon;
