import React from 'react';
import SVG from '@newrelic/gatsby-theme-newrelic/src/components/SVG';

const StatsdIcon = (props) => {
  return (
    <SVG {...props} viewBox="0 0 32 32">
      <g fillRule="nonzero" fill="none">
        <path
          d="M26.069 9.867l-.128-.203.16-.192c.97-1.163 1.493-3.2 1.728-4.437-.8.725-2.197 1.717-4.107 2.016l-.138.021-.118-.085a11.875 11.875 0 00-7.477-2.624c-2.741 0-5.323.906-7.467 2.613l-.117.096-.15-.032C6.358 6.741 4.97 5.75 4.182 5.024c.235 1.227.747 3.243 1.707 4.416l.16.192-.128.203a11.96 11.96 0 00-1.942 6.528c0 6.624 5.387 12.01 12.011 12.01S28 22.988 28 16.364a12.023 12.023 0 00-1.93-6.496h-.001zm-5.024-1.163c2.57 0 4.661 2.09 4.661 4.672 0 2.57-2.09 4.661-4.661 4.661-2.57 0-4.672-2.09-4.672-4.661.01-2.581 2.101-4.672 4.672-4.672zm-10.133 0c2.57 0 4.672 2.09 4.672 4.672 0 2.57-2.09 4.661-4.672 4.661a4.668 4.668 0 01-4.672-4.661c.01-2.581 2.101-4.672 4.672-4.672zm15.019 11.744h-1.61v1.515h-3.5v-1.515h-.98v5.27h-3.5v-5.27h-.97v4.245h-3.499v-4.245h-.97v2.144h-3.51v-2.144H6.027v-.661H25.94v.661h-.01z"
          fill="#94DEDE"
        />
        <path
          d="M28.651 4.064l.139-1.184-.726.95c-.01.02-1.61 2.058-4.309 2.538a12.503 12.503 0 00-7.765-2.667c-2.838 0-5.515.918-7.766 2.667-2.677-.49-4.266-2.517-4.277-2.539l-.725-.949.138 1.184c.022.15.427 3.659 1.878 5.61a12.593 12.593 0 00-1.92 6.689c0 6.986 5.685 12.672 12.672 12.672 6.986 0 12.672-5.686 12.672-12.672 0-2.358-.662-4.651-1.899-6.667 1.45-1.93 1.867-5.472 1.888-5.632zM15.979 28.373c-6.624 0-12.011-5.387-12.011-12.011 0-2.325.672-4.576 1.941-6.528l.128-.203-.16-.192c-.96-1.162-1.472-3.189-1.706-4.416.789.726 2.176 1.707 4.074 2.016l.15.022.117-.096a11.897 11.897 0 017.467-2.614c2.741 0 5.322.907 7.477 2.624l.117.086.139-.022c1.91-.298 3.317-1.29 4.107-2.016-.235 1.238-.758 3.275-1.728 4.438l-.16.192.128.202a11.995 11.995 0 011.93 6.507c0 6.624-5.386 12.011-12.01 12.011z"
          fill="#000"
        />
        <path
          d="M8.597 11.552l-.16.235-.864-.608a4.172 4.172 0 00-.533 1.194l1.035.224-.064.278-1.046-.224c-.042.234-.064.48-.064.725 0 .181.011.352.032.533l1.056-.181.054.277-1.056.182c.085.437.256.842.469 1.216l.907-.576.149.234-.907.576a4.013 4.013 0 003.296 1.74c1.302 0 2.464-.63 3.2-1.6l-.874-.62.16-.234.874.618c.235-.362.416-.768.523-1.194l-1.045-.224.064-.278 1.045.224a3.726 3.726 0 00.021-1.258l-1.045.181-.053-.277 1.045-.182a4.023 4.023 0 00-.48-1.216l-.885.576-.15-.234.886-.576a4.146 4.146 0 00-.875-.907l-.608.864-.235-.16.608-.864a3.878 3.878 0 00-1.194-.523l-.224 1.024-.278-.064.224-1.024a3.958 3.958 0 00-.714-.064c-.182 0-.363.011-.544.043l.181 1.035-.277.053-.182-1.035a4.298 4.298 0 00-1.216.47l.566.885-.235.15-.544-.886c-.341.235-.65.533-.907.864l.864.608zm2.059.907l.267-2.763.245 2.752a.95.95 0 01-.245 1.867.951.951 0 01-.95-.95.95.95 0 01.683-.906z"
          fill="#FFF"
        />
        <path
          d="M10.912 18.037c2.57 0 4.672-2.09 4.672-4.661 0-2.571-2.09-4.672-4.672-4.672a4.67 4.67 0 00-4.672 4.672 4.682 4.682 0 004.672 4.661zM8.64 10.08l.565.885.235-.15-.555-.895a3.744 3.744 0 011.216-.47l.182 1.035.277-.053-.181-1.035a4.67 4.67 0 01.544-.043c.245 0 .48.022.714.064l-.224 1.024.278.064.224-1.024c.426.107.832.288 1.194.523l-.608.864.235.16.608-.864c.33.256.63.555.875.907l-.886.576.15.234.885-.576c.224.374.384.779.48 1.216l-1.045.182.053.277 1.045-.181a3.726 3.726 0 01-.021 1.259l-1.045-.225-.064.278 1.045.224a3.993 3.993 0 01-.523 1.194l-.874-.618-.16.234.874.62a4 4 0 01-3.2 1.6 4.013 4.013 0 01-3.296-1.74l.907-.576-.15-.234-.906.576a3.744 3.744 0 01-.47-1.216l1.057-.182-.054-.277-1.056.181a4.342 4.342 0 01.032-1.258l1.046.224.064-.278-1.035-.224c.107-.426.288-.832.533-1.194l.864.608.16-.235-.864-.608c.224-.32.534-.619.875-.853z"
          fill="#000"
        />
        <path
          d="M18.731 11.552l-.16.235-.864-.608a4.172 4.172 0 00-.533 1.194l1.034.224-.064.278-1.034-.224c-.043.234-.064.48-.064.725 0 .181.01.352.032.533l1.056-.181.053.277-1.056.182c.085.437.256.842.47 1.216l.906-.576.15.234-.907.576a4.013 4.013 0 003.296 1.74c1.301 0 2.464-.63 3.2-1.6l-.875-.62.16-.234.875.618c.234-.362.416-.768.522-1.194l-1.045-.224.064-.278 1.045.224a3.726 3.726 0 00.022-1.258l-1.046.181-.053-.277 1.045-.182a4.023 4.023 0 00-.48-1.216l-.896.576-.149-.234.885-.576a4.146 4.146 0 00-.874-.907l-.608.864-.235-.16.608-.864a3.878 3.878 0 00-1.195-.523l-.224 1.024-.277-.064.224-1.024a3.958 3.958 0 00-.715-.064c-.181 0-.362.011-.544.043l.182 1.035-.278.053-.181-1.035a4.298 4.298 0 00-1.216.47l.565.885-.234.15-.566-.886c-.341.235-.65.533-.906.864l.885.608zm2.027.917l.266-2.762.352 2.773c.363.128.619.48.619.885 0 .523-.427.95-.95.95a.951.951 0 01-.949-.95.965.965 0 01.662-.896z"
          fill="#FFF"
        />
        <path
          d="M21.045 18.037c2.57 0 4.661-2.09 4.661-4.661 0-2.571-2.09-4.672-4.661-4.672a4.678 4.678 0 00-4.672 4.672 4.682 4.682 0 004.672 4.661zm-2.272-7.957l.565.885.235-.15-.565-.885a3.744 3.744 0 011.216-.469l.181 1.035.277-.054-.181-1.034a4.67 4.67 0 01.544-.043c.245 0 .48.021.715.064l-.224 1.024.277.064.224-1.024c.427.107.832.288 1.195.523l-.608.864.234.16.608-.864c.331.256.63.554.875.906l-.885.576.149.235.896-.576c.224.373.384.779.48 1.216l-1.045.181.053.278 1.045-.182a3.726 3.726 0 01-.021 1.259l-1.045-.224-.064.277 1.045.224a3.993 3.993 0 01-.523 1.195l-.874-.619-.16.235.874.619a4 4 0 01-3.2 1.6 4.013 4.013 0 01-3.296-1.739l.907-.576-.15-.235-.906.576a3.744 3.744 0 01-.47-1.216l1.057-.181-.054-.277-1.056.181a4.342 4.342 0 01.032-1.259l1.035.224.064-.277-1.035-.224c.107-.427.288-.832.534-1.195l.864.608.16-.234-.864-.608c.234-.331.544-.63.885-.864z"
          fill="#000"
        />
        <path
          fill="#FFF"
          d="M8.053 20.448h2.176v1.483H8.053zM12.533 20.448h2.176v3.584h-2.176z"
        />
        <path
          d="M11.168 12.459l-.256-2.752-.267 2.762c.086-.02.171-.042.267-.042.085 0 .17.01.256.032zM21.376 12.48l-.352-2.773-.267 2.762a.898.898 0 01.288-.042c.118 0 .224.021.331.053z"
          fill="#DA0100"
        />
        <path
          fill="#FFF"
          d="M17.003 20.448h2.176v4.608h-2.176zM21.483 20.448h2.176v1h-2.176z"
        />
        <path
          d="M20.821 19.787H6.026v.661h1.366v2.144h3.498v-2.144h.971v4.246h3.499v-4.246h.97v5.27h3.51v-5.27h.97v1.515h3.51v-1.515h1.62v-.661h-5.12.001zm-10.592 2.144H8.053v-1.483h2.176v1.483zm4.48 2.101h-2.176v-3.584h2.176v3.584zm4.47 1.024h-2.177v-4.608h2.176v4.608zm4.48-3.754h-2.177v-.854h2.176v.854zM10.912 14.315c.523 0 .95-.427.95-.95a.96.96 0 00-.705-.917.91.91 0 00-.512.011.95.95 0 00-.682.907c.01.533.426.949.949.949zM21.045 14.315c.523 0 .95-.427.95-.95a.938.938 0 00-.62-.885 1.117 1.117 0 00-.32-.053c-.106 0-.202.021-.287.043a.942.942 0 00-.651.896.92.92 0 00.928.949z"
          fill="#000"
        />
      </g>
    </SVG>
  );
};

export default StatsdIcon;
